// External Libraries
import { Link, MetaFunction } from '@remix-run/react';
import { 
  Stethoscope, // For doctors
  Users, // For nursing staff
  ClipboardList, // For administrative
  Shield, // For trust
  ArrowRight, // For arrow animation
} from 'lucide-react';

// Utils
import { setPageTitle } from '~/utils/config';
import { ModeToggle } from '~/components/mode-toggle';

const options = [
  { 
    value: 'doctor', 
    label: 'Physicians', 
    icon: Stethoscope,
    disabled: false,
    description: 'Access patient records, schedules, and clinical tools'
  },
  { 
    value: 'nurse', 
    label: 'Nursing Staff', 
    icon: Users,
    disabled: false,
    description: 'Manage patient care, medications, and daily workflows'
  },
  { 
    value: 'admin', 
    label: 'Administrative', 
    icon: ClipboardList,
    disabled: false,
    description: 'Handle scheduling, billing, and patient registration'
  },
]

export const meta: MetaFunction = () => {
  return [
    { title: setPageTitle("Medical Staff Portal") },
    {
      name: "description",
      content: "Secure access to patient records and clinical tools for medical professionals.",
    },
  ];
};

export default function HomePage() {
  return (
    <div className="min-h-screen bg-background relative flex flex-col">
      {/* Updated background elements with theme colors */}
      <div className="absolute inset-0 overflow-hidden pointer-events-none">
        <div className="absolute top-0 left-0 w-full h-[500px] bg-gradient-to-br from-primary/10 via-accent/5 to-transparent" />
        <div className="absolute bottom-0 right-0 w-full h-[500px] bg-gradient-to-tl from-accent/10 via-primary/5 to-transparent" />
        <div className="absolute inset-0 bg-dot-pattern" />
      </div>

      {/* Updated header */}
      <header className="sticky top-0 z-50 w-full border-b border-border bg-background/80 backdrop-blur-xl">
        <div className="container mx-auto px-4 h-16 flex items-center justify-between">
          <div className="flex items-center space-x-2">
            <Shield className="w-6 h-6 text-primary" />
            <span className="text-xl font-bold text-foreground">
              Medlink
            </span>
          </div>
          <ModeToggle />
        </div>
      </header>

      <main className="flex-1 container mx-auto px-4 py-12 relative z-10">
        <div className="max-w-screen-xl mx-auto space-y-12">
          {/* Updated hero section */}
          <div className="text-center space-y-4 max-w-3xl mx-auto">
            <h1 className="text-4xl md:text-5xl font-bold text-foreground">
              Medical Staff Portal
              <span className="block text-primary">
                Streamlined Healthcare Access
              </span>
            </h1>
            <p className="text-lg text-muted-foreground">
              Access your medical tools and patient records securely in one place
            </p>
          </div>
          
          {/* Updated cards grid */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 max-w-5xl mx-auto">
            {options.map((option) => (
              <Link
                key={option.value}
                to={`/login?role=${option.value}`}
                className="group relative p-6 rounded-[var(--radius)] bg-card border border-border shadow-sm hover:shadow-md hover:border-primary/50 transition-all duration-300"
              >
                <div className="flex flex-col h-full space-y-4">
                  <div className="p-3 rounded-xl bg-secondary w-fit">
                    <option.icon 
                      className="w-8 h-8 text-primary" 
                      strokeWidth={1.5}
                    />
                  </div>
                  <div className="space-y-2 flex-1">
                    <h2 className="text-xl font-semibold text-card-foreground">
                      {option.label}
                    </h2>
                    <p className="text-sm text-muted-foreground">
                      {option.description}
                    </p>
                  </div>
                  <div className="flex items-center text-sm font-medium text-primary">
                    Login <ArrowRight className="w-4 h-4 ml-1 group-hover:translate-x-1 transition-transform" />
                  </div>
                </div>
              </Link>
            ))}
          </div>

          {/* Updated help section */}
          <div className="mt-16 max-w-2xl mx-auto">
            <div className="rounded-[var(--radius)] bg-card border border-border p-6 text-center">
              <h2 className="text-xl font-semibold text-card-foreground mb-4">
                Need Assistance?
              </h2>
              <div className="flex flex-wrap justify-center gap-4">
                <button className="px-4 py-2 rounded-lg bg-secondary text-secondary-foreground hover:bg-secondary/80 transition-colors">
                  Technical Support
                </button>
                <button className="px-4 py-2 rounded-lg bg-secondary text-secondary-foreground hover:bg-secondary/80 transition-colors">
                  Password Reset
                </button>
                <button className="px-4 py-2 rounded-lg bg-secondary text-secondary-foreground hover:bg-secondary/80 transition-colors">
                  Training Resources
                </button>
              </div>
            </div>
          </div>
        </div>
      </main>

      {/* Updated footer */}
      <footer className="border-t border-border bg-background/80 backdrop-blur-xl">
        <div className="container mx-auto px-4 py-6 text-center space-y-2">
          <div className="text-sm text-muted-foreground">
            © {new Date().getFullYear()} Medlink. All rights reserved.
          </div>
          <div className="flex items-center justify-center gap-4 text-xs text-muted-foreground">
            <span>HIPAA Compliant</span>
            <span>•</span>
            <span>Secure Access</span>
            <span>•</span>
            <span>24/7 Support</span>
          </div>
        </div>
      </footer>
    </div>
  )
}
